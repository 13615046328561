
export default {
  data() {
    return {
      loading: false,
      TableLoading:false,
      dialogChangePassword: false,
      roles: [],
      listFactories:[],
      factorySelected:"",
      factorySelecteAdd:"",
      filtterRole:[],
      selectedRole: '',
      selectedEditRole: '',
      factoryuserID: '',
      server_errors: {
        email: '',
        roleId:''
      },
      factory: {},
      rolesID: {},
      factory_edit: {},
      factories: [],
      new_password:'',
      search:'',
      headers: [
        {
          text: "User ID",
          align: "start",
          sortable: false,
          value: "user_card_id",
        },
        {
          text: "User name",
          align: "start",
          value: "user_name",
        },
        { text: "Email", value: "user_email" },
        { text: "Role", value: "roleUserName" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      // ruleRole:[(v) => !!v || "User Role is required",],
      ruleRole: [(v) => !!v || 'User Role is required',],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Password is required",
        (v) =>
          (v && v.length >= 6) || "Password must be more than 6 characters",
      ],
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length >= 2) || 'Name must be less than 2 characters',
      ],
      toast: {
        value: true,
        color: 'success',
        msg: 'Success'
      },
      toast_error: {
        value: true,
        color: 'error',
        msg: 'Something when wrong!'
      }

    }
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },
    AddFactory() {
      if (this.$refs.form.validate() == true) {
        this.SubmitFactory();
      }
    },
    SubmitFactory() {
      this.factory.roleId = this.selectedRole;
      this.factory.factory_id = this.factorySelecteAdd;
      this.loading = true;

      this.$admin.post('register-factory-user', this.factory)
        .then(res => {
          if (res.data.success == true) {
            setTimeout(() => {
              this.loading = false;
              this.CloseModalAdd();
              this.factory = {};
              this.getFactory();
              this.$store.commit("Toast_State", this.toast);
            }, 300);
          }
        }).catch(error => {
          this.loading = false;
          this.$store.commit("Toast_State", this.toast_error);
          this.getFactory();
          // this.reset();
          if (error.response.status == 422) {
            let obj = error.response.data.errors;
            for (let [key, factory] of Object.entries(obj)) {
              this.server_errors[key] = factory[0];
            }
          }
        });
    },
    CloseModalAdd() {
      this.factory = {};
      this.reset();
      this.getFactory();
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.factory_edit = item;
      this.$store.commit("modalEdit_State", true);
    },
 
    UpdateFactory() {
      if (this.$refs.form.validate() == true) {
        this.loading = true;
        this.$admin.put('edit-factory-user/' + this.factory_edit.factoryUserId, {
          name: this.factory_edit.user_name,
          email: this.factory_edit.user_email,
          roleId: this.factory_edit.roleUser
        }) 
          .then(res => {
            if (res.data.success == true) {
              setTimeout(() => {
                this.loading = false;
                this.CloseModalEdit();
                this.factory_edit = {};
                this.reset();
                this.getFactory();
                this.$store.commit("Toast_State", this.toast);
              }, 300);
            }
          }).catch(error => {
            this.loading = false;
            this.$store.commit("Toast_State", this.toast_error);
            this.getFactory();
            if (error.response.status == 422) {
              let obj = error.response.data.errors;
              for (let [key, factory] of Object.entries(obj)) {
                this.server_errors[key] = factory[0];
              }
            }
          });
      }
    },

    CloseModalEdit() {
      this.reset();
      this.factory_edit = {};
      this.getFactory();
      this.$store.commit("modalEdit_State", false);
    },

    getFactory() {
      this.TableLoading = true;
      this.$admin.get('list-factory-user/'+ this.factorySelected).then(res => {
        // setTimeout(() => {
          this.factories = res.data.factoryUsers;
          this.TableLoading =false;
        // }, 100);
      }).catch(() => {
        this.TableLoading =false;
        this.$store.commit("Toast_State",this.toast_error);
      });

      this.$admin.get('list-role').then(res => {
        // this.roles = res.data.roles;
        this.TableLoading =false;
        var rolesUser = [
          "factorymanager", 
          "factoryuser", 
          "financemanager", 
          "financeuser",
          "warehousemanager",
          "warehouseuser",
          "buyuser",
          "staff",
      ];
      this.roles = res.data.roles.filter(item => rolesUser.includes(item.name));

      }).catch(() => {
        this.TableLoading =false;
        this.$store.commit("Toast_State",this.toast_error);
      });
    },

    getListFactory() {
      this.TableLoading = true;
      this.$admin.get('list-factories').then(res => {
        this.listFactories = res.data.data.data;
        this.factorySelected =res.data.data.data[0].id;
        this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },

    deleteItem(userId) {
      this.factoryuserID = userId;
      this.$store.commit("modalDelete_State", true);
    },

    deleteItemConfirm() {
      this.loading = true;
      this.$admin.delete('delete-factory-user/' + this.factoryuserID).then(res => {
        if (res.data.success == true) {
          setTimeout(() => {
            this.getFactory();
            this.$store.commit("modalDelete_State", false);
            this.loading = false;
            this.$store.commit("Toast_State", this.toast);
          }, 300);
        }
      }).catch(() => {
        this.getFactory();
        this.$store.commit("Toast_State", this.toast_error);
        this.$store.commit("modalDelete_State", false);
        this.loading = false;
      })
    },

    OpenchangePassword(userId) {
      this.new_password ='',
      this.factoryuserID = userId;
      this.dialogChangePassword = true;
    },

    ChangePassword(){
      if(this.$refs.form.validate() == true){
        this.loading = true;
        this.$admin.post('change-password',{
          password: this.new_password,
          userId: this.factoryuserID,
         }).then(res => {
             if(res.data.success == true){
            setTimeout(() =>{
              this.dialogChangePassword = false;
              this.new_password ='',
              this.loading = false;
              this.getFactory();
              this.reset();
              this.$store.commit("Toast_State",this.toast);
            },300)
             }
         }).catch(()=>{
          this.loading = false;
          this.getFactory();
          this.$store.commit("Toast_State",this.toast_error);
         })
      }
     },
     reset() {
      this.$refs.form.reset();
    },

  },
  computed: {
    resultSearch() {
      if (this.search) {
        return this.factories.filter((item) => {
          let filtered = true;
          if (this.search.length > 0) {
            filtered = item.user_name
              .toLowerCase()
              .includes(this.search.toLowerCase()) || item.user_email
              .toLowerCase()
              .includes(this.search.toLowerCase());
          }
          return filtered;
        });
      } else {
        return this.factories;
      }
    },
  },
  watch: {
    'factory.email': function () {
      this.server_errors.email = '';
    },
    'factorySelected': function () {
      this.getFactory();
    },
    'factory_edit.user_email': function () {
      this.server_errors.email = '';
    },
  },
  created() {
    this.getListFactory();
    if(this.factorySelected){
      this.getFactory();
    }
  },
}